import { useTranslation } from 'react-i18next';
const TeamIndex = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Team Start --> */}
        <div className="container-fluid team pb-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: 800}}>
                    <h4 className="text-primary">{t('indextext72')}</h4>
                    <h1 className="display-5 mb-4">{t('indextext73')}</h1>
                    <p className="mb-0">{t('indextext74')}</p>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="/img/team-1.jpg" className="img-fluid" alt=""/>
                            </div>
                            <div className="team-title">
                                <h4 className="mb-0">David James</h4>
                                <p className="mb-0">Profession</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="/img/team-2.jpg" className="img-fluid" alt=""/>
                            </div>
                            <div className="team-title">
                                <h4 className="mb-0">David James</h4>
                                <p className="mb-0">Profession</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="/img/team-3.jpg" className="img-fluid" alt=""/>
                            </div>
                            <div className="team-title">
                                <h4 className="mb-0">David James</h4>
                                <p className="mb-0">Profession</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="/img/team-4.jpg" className="img-fluid" alt=""/>
                            </div>
                            <div className="team-title">
                                <h4 className="mb-0">David James</h4>
                                <p className="mb-0">Profession</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Team End --> */}
    </>
    );
};
export default TeamIndex