import { useTranslation } from 'react-i18next';
const BlogStartIndex = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Blog Start --> */}
        <div className="container-fluid blog pb-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: '800px'}}>
                    <h4 className="text-primary">{t('indextext48')}</h4>
                    <h1 className="display-5 mb-4">{t('indextext49')}</h1>
                    <p className="mb-0">{t('indextext50')}</p>
                </div>
                <div className="owl-carousel blog-carousel wow fadeInUp" data-wow-delay="0.2s">
                    <div className="blog-item p-4">
                        <div className="blog-img mb-4">
                            <img src="/img/img2.jpg" className="img-fluid w-100 rounded" alt=""/>
                            <div className="blog-title">
                                <div href="#" className="btn">SA7</div>
                            </div>
                        </div>
                        <div href="#" className="h4 d-inline-block mb-3">{t('indextext56')}</div>
                        <p className="mb-4">{t('indextext51')}</p>
                        <p className="mb-4">{t('indextext52')}</p>
                        <p className="mb-4">{t('indextext53')}</p>
                        <p className="mb-4">{t('indextext54')}</p>
                        <p className="mb-4">{t('indextext55')}</p>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Blog End --> */}
    </>
    );
};

export default BlogStartIndex