// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // 支持从远程加载语言文件
  .use(LanguageDetector) // 自动检测用户语言
  .use(initReactI18next) // React 集成
  .init({
    fallbackLng: 'en', // 默认语言
    debug: true, // 开发时可以开启调试信息
    interpolation: {
      escapeValue: false, // React 已经有 XSS 保护
    },
  });

export default i18n;
