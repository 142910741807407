import { useTranslation } from 'react-i18next';
const FeaturesIndex = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Features Start --> */}
        <div className="container-fluid feature pb-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: '800px'}}>
                    <h4 className="text-primary">{t('indextext27')}</h4>
                    <h1 className="display-5 mb-4">{t('indextext28')}</h1>
                    <p className="mb-0">{t('indextext29')}</p>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-chart-line fa-4x text-primary"></i>
                            </div>
                            <h4>{t('indextext30')}</h4>
                            <p className="mb-4">{t('indextext31')}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-university fa-4x text-primary"></i>
                            </div>
                            <h4>{t('indextext32')}</h4>
                            <p className="mb-4">{t('indextext33')}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-file-alt fa-4x text-primary"></i>
                            </div>
                            <h4>{t('indextext34')}</h4>
                            <p className="mb-4">{t('indextext35')}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-hand-holding-usd fa-4x text-primary"></i>
                            </div>
                            <h4>{t('indextext36')}</h4>
                            <p className="mb-4">{t('indextext37')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Features End --> */}
    </>
    );
};
export default FeaturesIndex