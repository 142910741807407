import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NavHeader from '../commoment/nav';
import LogoIndex from '../layout/logo';
const Feature = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <LogoIndex/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <NavHeader/>
            </nav>

            {/* <!-- Header Start --> */}
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{maxWidth: 900}}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">{t('fenxianpilou')}</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><Link to="/">{t('index')}</Link></li>
                        <li className="breadcrumb-item active text-primary">{t('fenxianpilou')}</li>
                    </ol>    
                </div>
            </div>
            {/* <!-- Header End --> */}
        </div>
        {/* <!-- Navbar & Hero End --> */}
        <div className="container-fluid service py-5" style={{maxWidth: 900}}>
            <div className="wow fadeInUp" data-wow-delay="0.2s">
                <p>{t('fenxianpilou2')}</p>
                <p>{t('fenxianpilou3')}</p>
                <p>{t('fenxianpilou5')}</p>
                <p>{t('fenxianpilou6')}</p>
                <p>{t('fenxianpilou7')}</p>
                <p>{t('fenxianpilou8')}</p>
                <p>{t('fenxianpilou9')}</p>
                <p>{t('fenxianpilou10')}</p>
                <p>{t('fenxianpilou11')}</p>
                <p>{t('fenxianpilou12')}</p>
                <p>{t('fenxianpilou13')}</p>
                <p>{t('fenxianpilou14')}</p>
            </div>
            
        </div>
    </>
    );
};
export default Feature