import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NavHeader from '../commoment/nav';
import React, { useEffect, useRef } from 'react';
import LogoIndex from '../layout/logo';
const Offer = () => {
    const { t } = useTranslation();
    const iframeRef = useRef(null);
    useEffect(() => {
        const iframeDoc = iframeRef.current?.contentDocument;
        if (iframeDoc) {
          iframeDoc.body.style.backgroundColor = 'lightblue';
        }
      }, []);
    return (
    <>
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <LogoIndex/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <NavHeader/>
            </nav>

            {/* <!-- Header Start --> */}
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{maxWidth: 900}}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">{t('xinwenzixun')}</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><Link to="/">{t('index')}</Link></li>
                        <li className="breadcrumb-item active text-primary">{t('xinwenzixun')}</li>
                    </ol>    
                </div>
            </div>
            {/* <!-- Header End --> */}
        </div>
        {/* <!-- Navbar & Hero End --> */}
        <div className="container-fluid service py-5" style={{maxWidth: 900}} >
            <div className="wow fadeInUp" data-wow-delay="0.2s">
                <iframe
                    ref={iframeRef}
                    src="https://www.tradays.com/en/economic-calendar/widget?mode=1&utm_source=www.gffxx-ff6.com" 
                    width="100%"
                    height="800px"
                    title="news"
                    style={{ border: 'none' }}
                ></iframe>
            </div>
        </div>
    </>
    );
};
export default Offer