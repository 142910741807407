import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NavHeader from '../commoment/nav';
import LogoIndex from '../layout/logo';
const Contact = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <LogoIndex/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <NavHeader/>
            </nav>

            {/* <!-- Header Start --> */}
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{maxWidth: 900}}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">{t('contact')}</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><Link to="/">{t('index')}</Link></li>
                        <li className="breadcrumb-item active text-primary">{t('contact')}</li>
                    </ol>    
                </div>
            </div>
            {/* <!-- Header End --> */}
        </div>
        {/* <!-- Navbar & Hero End --> */}
        <div className="container-fluid contact py-5">
            <div className="container py-5">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                    <div className="bg-light rounded p-5 mb-5">
                        <h4 className="text-primary mb-4">{t('contact')}</h4>
                        <div className="contact-add-item">
                            <div className="contact-icon text-primary mb-4">
                                <i className="fas fa-envelope fa-2x"></i>
                            </div>
                            <div>
                                <h4>{t('mailUs')}</h4>
                                <p className="mb-0">info@example.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};
export default Contact