import { useTranslation } from 'react-i18next';
const ServicesIndex = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Services Start --> */}
        <div className="container-fluid service pb-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: '800px'}}>
                    <h4 className="text-primary">{t('indextext12')}</h4>
                    <h1 className="display-5 mb-4">{t('indextext13')}</h1>
                    <p className="mb-0">{t('indextext14')}</p>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-1.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext15')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext16')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-2.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext17')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext18')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-3.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext19')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext20')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-4.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext21')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext22')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-5.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext23')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext24')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="/img/service-6.jpg" className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="rounded-bottom p-4">
                                <div href="#" className="h4 d-inline-block mb-4">{t('indextext25')}</div>
                                <p className="mb-4" style={{minHeight:150}}>{t('indextext26')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Services End --> */}
    </>
    );
};
export default ServicesIndex