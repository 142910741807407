import { Link } from "react-router-dom";
const LogoIndex = () => {
    return (
    <>
       <Link to="/" className="navbar-brand p-0">
            {/* <i className="fas fa-search-dollar me-3"></i> */}
            <h1 className="text-primary"><img src="/img/logo.png" alt="Logo"/>SA7</h1>
            {/* <img src="/img/logo.png" alt="Logo"/> */}
        </Link> 
    </>
    );
};
export default LogoIndex