
import NavHeader from '../commoment/nav';
import CarouselIndex from './carousel';
import AboutIndex from '../commoment/about';
import ServicesIndex from '../commoment/servicesIndex';
import FeaturesIndex from '../commoment/featuresIndex';
import OfferIndex from '../commoment/oferStart';
import BlogStartIndex from '../commoment/blogStart';
import FaqsIndex from '../commoment/faqsIndex';
import TeamIndex from '../commoment/teamIndex';
import LogoIndex from '../layout/logo';
const Home = () => {
    return (
        <>
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                   <LogoIndex/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>
                    <NavHeader/>
                </nav>
                <CarouselIndex/>
            </div>
            <AboutIndex/>
            <ServicesIndex/>
            <FeaturesIndex/>
            <OfferIndex/>
            <BlogStartIndex/>
            <FaqsIndex/>
            <TeamIndex/>
        </>
    );
};
export default Home