import { useTranslation } from 'react-i18next';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import {GlobalOutlined} from '@ant-design/icons'
import {FloatButton,List} from 'antd';
import {useEffect} from 'react';
const NavHeader = () => {
    useEffect(() => {
        console.log('222')
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 平滑滚动
        });
    }, [])
    const {pathname}=useLocation()
    const { t ,i18n} = useTranslation();
    const navigate = useNavigate();
    const  linkList=[
        {name:t('index'),src:'/'},
        {name:t('about'),src:'/about'},
        {name:t('service'),src:'/service'},
        {name:t('Privacy'),src:null,child:[
            {name:t('fenxianpilou'),src:'/feature'},
            {name:t('yisizhengce'),src:'/team'},
            {name:t('mzsm'),src:'/testimonial'},
        ]},
        // {name:'Blogs',src:'/blog'},
        {name:t('hangqingzixun'),src:null,child:[
            {name:t('xinwenzixun'),src:'/offer'},
            {name:t('caijinrili'),src:'/faq'},
        ]},
        {name:t('contact'),src:'/contact'},
    ]
    const goToContact = (src) => {
        navigate(src);
    };
    const language=[
        {key:'en',value:'English'},
        {key:'tw',value:'繁體中文'},
        {key:'zh',value:'简体中文'},
        {key:'jp',value:'日本語'},
        {key:'ko',value:'한국인'},
        {key:'sp',value:'español'},
        {key:'th',value:'แบบไทย'},
    ]
    const tooltipDoc=()=>{
        return(
            <div>
                <List
                    size="small"
                    dataSource={language}
                    renderItem={(item) => 
                    <List.Item>
                        <div style={{color: i18n.resolvedLanguage===item.key?'#00d084':'#fff',cursor:'pointer'}} onClick={()=>{i18n.changeLanguage(item.key)}}>{item.value}</div>
                    </List.Item>}
                />
            </div>
        )
    }
    return (
    <>
       <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
                {linkList.map((item ,i)=>{
                    if(item.child){
                        return (
                            <div className="nav-item dropdown" key={i}>
                                <div href="#" className="nav-link" data-bs-toggle="dropdown">
                                    <span className="dropdown-toggle">{item.name}</span>
                                </div>
                                <div className="dropdown-menu m-0">
                                    {item.child.map((items,j) => <Link onClick={()=>goToContact(items.src)} key={`${i+j}`} to={items.src} className={`${pathname===items.src?'active':''} dropdown-item`} >{items.name}</Link>)}
                                </div>
                            </div>
                        )
                    }else{
                        return <Link key={i} onClick={()=>goToContact(item.src)} to={item?.src} className={`${pathname===item.src?'active':''} nav-item nav-link`} >{item.name}</Link>
                    }
                })}
            </div>
            <a href="https://www.baidu.com/" target='_blank' className="btn btn-primary rounded-pill py-2 px-4 my-3 my-lg-0 flex-shrink-0" rel="noreferrer">{t('Register')}</a>
            <FloatButton icon={<GlobalOutlined />}   style={{ insetBlockStart: 200,background:'#00d084' }} tooltip={tooltipDoc}/>
        </div>
    </>
    );
};
export default NavHeader