import { useTranslation } from 'react-i18next';
const CarouselIndex = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Carousel Start --> */}
        <div className="header-carousel owl-carousel" style={{overflow:'hidden'}}>
            <div className="header-carousel-item">
                <img src="/img/carousel-1.jpg" className="img-fluid w-100" alt=""/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row gy-0 gx-5">
                            <div className="col-lg-0 col-xl-5"></div>
                            <div className="col-xl-7 animated fadeInLeft">
                                <div className="text-sm-center text-md-end">
                                    <h4 className="text-primary text-uppercase fw-bold mb-4">{t('indextext1')}</h4>
                                    <h1 className="display-6 text-uppercase text-white mb-4">{t('indextext2')}</h1>
                                    <p className="mb-5 fs-5">{t('indextext3')}</p>
                                    <div className="d-flex justify-content-center justify-content-md-end flex-shrink-0 mb-4">
                                        <a href="https://play.google.com/store/apps/details?id=com.vinqim.vinqim" target='_blank' className="btn btn-light rounded-pill py-3 px-4 px-md-5 me-2" rel="noreferrer"  >{t('indextext4')}</a>
                                        <a href="https://apps.apple.com/us/app/stockease/id6738416500?l=zh-Hans-CN" target='_blank'  className="btn btn-primary rounded-pill py-3 px-4 px-md-5 ms-2" rel="noreferrer">{t('indextext5')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Carousel End --> */}
    </>
    );
};
export default CarouselIndex