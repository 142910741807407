import React  from 'react';
import { Route, Routes } from 'react-router-dom';
import './i18n'; // 引入 i18n 配置
import Header from './layout/Header';
import Home from './home';
import About from './about';
import Contact from './contact';
import Faq from './faq';
import Feature from './feature';
import Offer from './offer';
import Service from './service';
import Team from './team';
import Testimonial from './testimonial';
import Fotter from './layout/Footer';
function App() {
  return (
    <>
      <Header/>
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/feature" element={<Feature />} />
            <Route path="/offer" element={<Offer />} />
            <Route path="/service" element={<Service />} />
            <Route path="/team" element={<Team />} />
            <Route path="/testimonial" element={<Testimonial />} />
      </Routes>
      <Fotter/>
    </>
  );
}
export default App;
