/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Fotter = () => {
    const { t } = useTranslation();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // 平滑滚动
        });
      };
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
                <div className="container py-5 border-start-0 border-end-0" style={{border: '1px solid', borderColor: 'rgb(255, 255, 255, 0.08)'}}>
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="footer-item">
                                <Link to="/" className="p-0">
                                {/* <i className="fas fa-search-dollar me-3"></i> */}
                                    <h4 className="text-white"><img style={{width:40,height:40}} src="/img/logo.png" alt="Logo"/> SA7</h4>
                                    
                                </Link>
                                <p className="mb-4">{t('comDesc')}</p>
                                <div className="d-flex">
                                    <a href="https://apps.apple.com/us/app/stockease/id6738416500?l=zh-Hans-CN" target='_blank' className="bg-primary d-flex rounded align-items-center py-2 px-3 me-2" rel="noreferrer">
                                        <i className="fas fa-apple-alt text-white"></i>
                                        <div className="ms-3">
                                            <small className="text-white">{t('indextext5')}</small>
                                            <h6 className="text-white">App Store</h6>
                                        </div>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.vinqim.vinqim" target='_blank' className="bg-dark d-flex rounded align-items-center py-2 px-3 ms-2" rel="noreferrer">
                                        <i className="fas fa-play text-primary"></i>
                                        <div className="ms-3">
                                            <small className="text-white">{t('indextext4')}</small>
                                            <h6 className="text-white">Google Play</h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-2">
                            <div className="footer-item">
                                <h4 className="text-white mb-4">{t('QuickLinks')}</h4>
                                <Link to="/about"><i className="fas fa-angle-right me-2"></i> {t('about')}</Link>
                                <Link to="/service"><i className="fas fa-angle-right me-2"></i>{t('service')}</Link>
                                <Link to="/contact"><i className="fas fa-angle-right me-2"></i>{t('contact')}</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item">
                                <h4 className="text-white mb-4">{t('Support')}</h4>
                                <Link to='/feature'><i className="fas fa-angle-right me-2"></i> {t('fenxianpilou')}</Link>
                                <Link to='/team'><i className="fas fa-angle-right me-2"></i> {t('yisizhengce')}</Link>
                                <Link to='/testimonial'><i className="fas fa-angle-right me-2"></i> {t('mzsm')}</Link>
                                <Link to='/offer'><i className="fas fa-angle-right me-2"></i> {t('xinwenzixun')}</Link>
                                <Link to='/faq'><i className="fas fa-angle-right me-2"></i> {t('caijinrili')}</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item">
                                <h4 className="text-white mb-4">{t('ContactInfo')}</h4>
                                {/* <div className="d-flex align-items-center">
                                    <i className="fas fa-map-marker-alt text-primary me-3"></i>
                                    <p className="text-white mb-0">123 Street New York.USA</p>
                                </div> */}
                                <div className="d-flex align-items-center">
                                    <i className="fas fa-envelope text-primary me-3"></i>
                                    <p className="text-white mb-0">info@example.com</p>
                                </div>
                                {/* <div className="d-flex align-items-center">
                                    <i className="fa fa-phone-alt text-primary me-3"></i>
                                    <p className="text-white mb-0">(+012) 3456 7890</p>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <i className="fab fa-firefox-browser text-primary me-3"></i>
                                    <p className="text-white mb-0">Yoursite@ex.com</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
            {/* <!-- Copyright Start --> */}
            <div className="container-fluid copyright py-4">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6 text-center text-md-start mb-md-0">
                            <span className="text-body border-bottom text-white"> © 2021 SA7 GROUP LIMITED.</span>
                        </div>
                        <div className="col-md-6 text-center text-md-end text-body">
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Copyright End --> */}
            {/* <!-- Back to Top --> */}
            <div onClick={scrollToTop}  className="btn btn-primary btn-lg-square rounded-circle back-to-top"><i className="fa fa-arrow-up"></i></div>  
        </>
    )
};
export default Fotter