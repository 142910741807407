import { useTranslation } from 'react-i18next';
const Header = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="container-fluid topbar bg-light px-5 d-none d-lg-block">
                <div className="row gx-0 align-items-center">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-flex flex-wrap">
                            {/* <div href="#" className="text-muted small me-4"><i className="fas fa-map-marker-alt text-primary me-2"></i>Find div Location</div>
                            <div href="tel:+01234567890" className="text-muted small me-4"><i className="fas fa-phone-alt text-primary me-2"></i>+01234567890</div> */}
                            <div href="mailto:example@gmail.com" className="text-muted small me-0"><i className="fas fa-envelope text-primary me-2"></i>info@example.com</div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{height: '45px'}}>
                            <a href="https://www.baidu.com/" target='_blank' rel="noreferrer"><small className="me-3 text-dark"><i className="fa fa-user text-primary me-2"></i>{t('Register')}</small></a>
                            <a href="https://www.baidu.com/" target='_blank' rel="noreferrer"><small className="me-3 text-dark"><i className="fa fa-sign-in-alt text-primary me-2"></i>{t('Login')}</small></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Header