import { useTranslation } from 'react-i18next';
import NavHeader from '../commoment/nav';
import AboutIndex from '../commoment/about';
import FeaturesIndex from '../commoment/featuresIndex';
import TeamIndex from '../commoment/teamIndex';
import BlogStartIndex from '../commoment/blogStart';
import { Link } from 'react-router-dom';
import LogoIndex from '../layout/logo';
const About = () => {
    const { t } = useTranslation();
    return (
    <>
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <LogoIndex/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <NavHeader/>
            </nav>

            {/* <!-- Header Start --> */}
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{maxWidth: 900}}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">{t('about')}</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><Link to="/">{t('index')}</Link></li>
                        <li className="breadcrumb-item active text-primary">{t('about')}</li>
                    </ol>    
                </div>
            </div>
            {/* <!-- Header End --> */}
        </div>
        {/* <!-- Navbar & Hero End --> */}
        <AboutIndex/>
        <BlogStartIndex/>
        <FeaturesIndex/>
        <TeamIndex/>
    </>
    );
};
export default About