import { useTranslation } from 'react-i18next';
const OfferIndex = () => {
    const { t } = useTranslation();
    return (
    <>
       {/* <!-- Offer Start --> */}
        <div className="container-fluid offer-section pb-5">
            <div className="container pb-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: '800px'}}>
                    <h4 className="text-primary">{t('indextext38')}</h4>
                    <h1 className="display-5 mb-4">{t('indextext39')}</h1>
                    <p className="mb-0">{t('indextext40')}</p>
                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.2s">
                        <div className="nav nav-pills bg-light rounded p-5">
                            <div className="accordion-link p-4 active mb-4" data-bs-toggle="pill" href="#collapseOne">
                                <h5 className="mb-0">{t('indextext44')}</h5>
                            </div>
                            <div className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseTwo">
                                <h5 className="mb-0">{t('indextext45')}</h5>
                            </div>
                            <div className="accordion-link p-4 mb-4" data-bs-toggle="pill" href="#collapseThree">
                                <h5 className="mb-0">{t('indextext46')}</h5>
                            </div>
                            <div className="accordion-link p-4 mb-0" data-bs-toggle="pill" href="#collapseFour">
                                <h5 className="mb-0">{t('indextext47')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.4s">
                        <div className="tab-content">
                            <div id="collapseOne" className="tab-pane fade show p-0 active">
                                <div className="row g-4">
                                    <div className="col-md-7">
                                        <img src="/img/offer-1.jpg" className="img-fluid w-100 rounded" alt=""/>
                                    </div>
                                    <div className="col-md-5">
                                        <h3 className="display-6 mb-4">{t('indextext41')}</h3>
                                        <p className="mb-4">{t('indextext42')}</p>
                                        <a href="https://www.baidu.com/" target='_blank' className="btn btn-primary rounded-pill py-2 px-4" rel="noreferrer">{t('indextext43')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Offer End --> */}
    </>
    );
};
export default OfferIndex